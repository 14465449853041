<template>
	<div class="space-y-3">
		<div class="flex justify-center">
			<span class="rounded-full bg-gray-100 p-4 text-black text-opacity-50">
				<Icon
					name="uil:bell"
					size="40"
				/>
			</span>
		</div>

		<div class="text-center text-black">
			<p class="text-base font-semibold">
				{{ $t('No tienes notificaciones') }}
			</p>
			<span class="text-sm">
				{{ $t('Las notificaciones nuevas aparecerán aquí') }}
			</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'EmptyNotifications',
}
</script>
