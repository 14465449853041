<script setup lang="ts">
const route = useRoute()
const router = useRouter()
const localePath = useLocalePath()
const { $tracking, $subscription } = useNuxtApp()
const { t: $t } = useI18n()
const { createOfferPath } = useCreateOffer()

const isSearchFilterApplied = computed(() => {
	const { query } = route
	if (query === '{}') return false

	return route.query?.applied_filter
})

const getSearchFilterParameters = computed(() => {
	const parametersToFilter = ['role_id']
	const query = route.query

	const params = Object.keys(query).reduce((obj, key) => {
		if (parametersToFilter.includes(key)) {
			obj[key] = query[key]
		}
		return obj
	}, {})

	return params
})

const goToCreateOffer = () => {
	$tracking.offer.offerCreationView()

	if (!isSearchFilterApplied.value) {
		router.push(localePath(createOfferPath.value))
		return
	}

	router.push(
		localePath({
			path: createOfferPath.value,
			query: getSearchFilterParameters.value,
		}),
	)
}
</script>

<template>
	<div class="hidden 2xl:grid">
		<UButton
			size="sm"
			variant="outline"
			:to="$subscription.isSubscriptionFree ? '/subscription/buy' : '/subscription/update'"
		>
			<Icon
				name="material-symbols:star"
				class="mr-1"
				size="20"
			/>
			Actualizar plan
		</UButton>
	</div>

	<div class="hidden 2xl:grid">
		<UButton
			size="sm"
			@click="goToCreateOffer"
		>
			<Icon
				name="uil:plus"
				class="mr-1"
				size="20"
			/>
			{{ $t('Configura tu búsqueda') }}
		</UButton>
	</div>
</template>

<style scoped></style>
