<template>
	<div class="flex justify-between rounded shadow pr-5 bg-white hover:bg-gray-200 mb-20">
		<div
			class="w-1 bg-purple-600"
			:style="{ height: 'unset' }"
		/>

		<div class="flex-1 flex justify-between items-center">
			<div class="py-3 px-5 space-y-1.5 text-primary-500 w-full">
				<div class="bg-gray-300 h-5 w-full" />
				<div class="bg-gray-300 h-2 w-full" />
				<div class="bg-gray-300 h-2 w-4/5" />
				<div class="bg-gray-300 h-2 w-2/5" />

				<div class="bg-gray-300 h-2 w-1/5" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SkeletonNotification',
}
</script>
