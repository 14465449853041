<template>
	<UDropdown
		:items="listOptions"
		:ui="{ item: { disabled: 'cursor-default opacity-100' } }"
	>
		<UChip :show="isActiveOrder">
			<UButton
				color="gray"
				size="xs"
				icon="i-ic-outline-filter-alt"
			>
				{{ $t('Ordenar') }}
			</UButton>
		</UChip>

		<template #title="{ item }">
			<span class="flex items-center w-full text-neutral-700">
				<UIcon
					:name="item.icon"
					size="16"
					class="mr-2"
				/>
				<span class="font-medium">{{ item.label }}</span>
			</span>
		</template>

		<template #item="{ item }">
			<span class="flex items-center justify-between w-full">
				<span :class="item.isActive && 'text-primary-500'">{{ item.label }}</span>
				<span
					v-if="item.isActive"
					class="w-1.5 h-1.5 rounded-full block bg-primary-500"
				/>
			</span>
		</template>
	</UDropdown>
</template>

<script>
import { mapState } from 'pinia'
import { useNotificationsStore } from '~/store/notifications'

export default {
	name: 'FilterNotifications',
	data: () => ({
		classOption: 'text-xs flex justify-between items-center rounded hover:bg-primary-50 px-2.5 py-1.5',
		orderByDate: [
			{ value: 'desc', label: 'Descendiente' },
			{ value: 'asc', label: 'Ascendiente' },
		],
		orderByStatus: [
			{ value: 0, label: 'No leídas' },
			{ value: 1, label: 'Leídas' },
		],
	}),
	computed: {
		...mapState(useNotificationsStore, ['filters']),
		isActiveOrder() {
			return this.filters.order_by_date !== null || this.filters.read !== null
		},
		listOptions() {
			return [
				[
					{
						value: 'desc',
						label: 'Por fecha',
						slot: 'title',
						icon: 'i-material-symbols-calendar-today-outline',
						disabled: true,
					},
					{
						value: 'desc',
						label: 'Descendiente',
						isActive: this.filters.order_by_date === 'desc',
						click: () => this.changeFilter('order_by_date', 'desc'),
					},
					{
						value: 'asc',
						label: 'Ascendiente',
						isActive: this.filters.order_by_date === 'asc',
						click: () => this.changeFilter('order_by_date', 'asc'),
					},
				],
				[
					{
						value: 0,
						label: 'Estado',
						slot: 'title',
						icon: 'i-ic-twotone-check-circle-outline',
						disabled: true,
					},
					{
						value: 0,
						label: 'No leídas',
						isActive: this.filters.read === 0,
						click: () => this.changeFilter('read', 0),
					},
					{
						value: 1,
						label: 'Leídas',
						isActive: this.filters.read === 1,
						click: () => this.changeFilter('read', 1),
					},
				],
			]
		},
	},
	methods: {
		changeFilter(type, value) {
			const filters = { ...this.filters }

			if (filters[type] === value) {
				filters[type] = null
				this.emitChange(filters)
				return
			}

			filters[type] = value
			this.emitChange(filters)
		},
		emitChange(filters) {
			this.$emit('change', filters)
		},
	},
}
</script>
