<template>
	<header
		id="hire-navbar"
		class="bg-white border-b border-gray-300 w-full"
	>
		<div class="flex justify-between items-stretch gap-3 px-4 lg:px-6">
			<NuxtLinkLocale
				to="/"
				class="flex mr-3 items-center justify-center"
			>
				<ULogo
					type="hire"
					class="w-36"
				/>
			</NuxtLinkLocale>

			<nav
				v-if="$auth?.user?.wizard?.completed"
				class="hidden lg:block flex-1 justify-between"
			>
				<ul class="h-full flex items-stretch">
					<li
						v-for="link in navLinks"
						:key="link.path"
					>
						<NuxtLinkLocale
							v-slot="{ isActive }"
							:to="link.path"
						>
							<div
								class="h-full px-5 pt-px flex items-center justify-center text-sm border-t-3"
								:class="
									isActive
										? 'bg-primary-50 font-semibold text-primary-700 border-primary-700'
										: 'border-white text-gray-500 '
								"
							>
								<Icon
									:name="link.icon"
									size="16"
									class="mr-1.5"
								/>
								{{ link.label }}
							</div>
						</NuxtLinkLocale>
					</li>
				</ul>
			</nav>

			<div class="flex items-center gap-4">
				<ActionsMenuDesktop v-if="$auth?.user?.wizard?.completed" />
				<NotificationMenu v-if="$auth?.user?.wizard?.completed" />
				<UserMenu />
			</div>
		</div>

		<div>
			<nav
				v-if="$auth?.user?.wizard?.completed"
				class="lg:hidden flex-1 flex justify-stretch"
			>
				<ul class="h-full flex-1 flex items-stretch justify-stretch">
					<li
						v-for="link in navLinks"
						:key="link.path"
						class="flex-1"
					>
						<NuxtLinkLocale
							v-slot="{ isActive }"
							:to="link.path"
						>
							<div
								class="h-full pt-3 pb-3 flex items-center justify-center text-xs text-gray-400"
								:class="
									isActive
										? 'bg-primary-50  font-semibold text-primary-700 border-t-3 border-primary-700'
										: 'border  border-gray-100'
								"
							>
								{{ link.label }}
							</div>
						</NuxtLinkLocale>
					</li>
				</ul>
			</nav>
		</div>
	</header>
</template>

<script setup>
import UserMenu from '~/components/layout/header/user-menu/UserMenu.vue'
import NotificationMenu from '~/components/layout/header/NotificationMenu.vue'
import ActionsMenuDesktop from '~/components/layout/header/ActionsMenuDesktop.vue'

defineOptions({ name: 'HeaderApp' })

const { t: $t } = useI18n()

const navLinks = computed(() => {
	return [
		{
			label: $t('Mis búsquedas'),
			path: '/',
			icon: 'heroicons:briefcase',
		},
		{
			label: $t('Buscar candidatos'),
			path: '/candidates',
			icon: 'heroicons:magnifying-glass',
		},
		{
			label: $t('Mis usuarios'),
			path: '/users',
			icon: 'heroicons:user-group',
		},
	]
})
</script>
